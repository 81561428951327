<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-6 w-6 inline text-green-700"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
  </svg>
</template>
